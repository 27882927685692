// import { LayoutStyles } from 'frontend/src/styles/layout'
// import NextLink from 'next/link'
import styled from "styled-components";
// import SvgLogo from '../svg/SvgLogo'
import SanityMenu from "../sanity/SanityMenu";
import {
  Breakpoint,
  Columns,
  MobileBreakpoint,
  TabletBreakpoint,
} from "../../styles/functions";
import { useNavigation } from "../../contexts/NavigationProvider";
import UtilWrapper from "../util/UtilWrapper";
import { TypeBodyM, TypeCaptionL, TypeCaptionM } from "../../styles/mixins";
import LinkStudio from "../link/LinkStudio";
import FormNewsletter from "../form/FormNewsletter";
import ErrorBoundary from "../ErrorBoundary";
import FooterLogos from "./FooterLogos";

type Props = {
  className?: string;
};

const FooterDesktop: React.FC<Props> = ({ className }: Props) => {
  const navigation = useNavigation();
  const footer = navigation?.find((m) => m._id == "footer-desktop");
  if (!footer) return null;

  const {
    menu1,
    menu2,
    menu3,
    menu4,
    menu5,
    menu6,
    right1,
    right2,
    right3,
    images,
  } = footer;

  console.log(images[0]);

  const year = new Date().getFullYear();

  return (
    <ErrorBoundary>
      <Header className={className}>
        <Top>
          <LeftMenu>
            <SanityMenu layout="SUBMENU" links={menu1} />
            <SanityMenu layout="SUBMENU" links={menu2} />
            <SanityMenu layout="SUBMENU" links={menu3} />
            <SanityMenu layout="SUBMENU" links={menu4} />
            <SanityMenu layout="SUBMENU" links={menu5} />
            <SanityMenu layout="SUBMENU" links={menu6} />
          </LeftMenu>

          <RightMenu>
            <SanityMenu layout="SUBMENU" links={right1} />
            <SanityMenu layout="SUBMENU" links={right2} />
            <SanityMenu layout="SUBMENU" links={right3} />
            <Logos footer={footer} />
          </RightMenu>
        </Top>
        <Bottom>
          <Newsletter>
            <NewsletterHeading>Subscribe to our Newsletter</NewsletterHeading>
            <FormNewsletter />
          </Newsletter>

          <Copy>&copy; {year} Yu Mei</Copy>

          <Studio />
        </Bottom>
      </Header>
    </ErrorBoundary>
  );
};

const Header = styled.footer`
  padding-top: var(--spacing-24);
  padding-bottom: 20px;
  background: var(--color-background);

  ${MobileBreakpoint} {
    display: none;
  }
`;

const Top = styled(UtilWrapper)`
  grid-template-columns: ${Columns(9)} ${Columns(9)} ${Columns(6)};
  gap: var(--gutter);
  display: grid;
  margin-bottom: 112px;

  h2 {
    ${TypeCaptionL}
  }

  ${TabletBreakpoint(`
    grid-template-columns: ${Columns(14)} ${Columns(2)} ${Columns(8)};
  `)}
`;

const MenuWrap = styled.nav`
  display: grid;
  gap: 40px var(--gutter);
  margin-bottom: auto;
`;

const LeftMenu = styled(MenuWrap)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const RightMenu = styled(MenuWrap)`
  grid-template-columns: 1fr 1fr;
  grid-column: auto / -1;
`;

const Bottom = styled(UtilWrapper)`
  grid-template-columns: ${Columns(18)} ${Columns(3)} ${Columns(3)};
  gap: var(--gutter);
  display: grid;
  align-items: flex-end;

  h2 {
    ${TypeCaptionL}
    margin-bottom: 1em;
  }
`;

const Newsletter = styled.div`
  width: ${Columns(6)};
`;

const NewsletterHeading = styled.h2`
  ${TypeBodyM};
  margin-bottom: 16px;
`;

const Copy = styled.div`
  ${TypeCaptionM};
  color: var(--color-charcoal600);
`;

const Logos = styled(FooterLogos)``;

const Studio = styled(LinkStudio)`
  ${TypeCaptionM};
  color: var(--color-charcoal600);
`;

export default FooterDesktop;
