const GroqLink = /* groq */ `
  ...,
  'title': select(
    defined(title) => title,
    reference->_type == 'product' => reference->title,
    reference->_type == 'collection' => reference->title,
    reference->_type == 'article' => reference->title,
    reference->_type == 'promo' => reference->title,
    reference->_type == 'page' => reference->title,
  ),
  'href': select(
    reference->_type == 'collection' => '/collections/' + reference->store.slug.current,
    reference->_type == 'product' => '/products/' + reference->store.slug.current,
    
    reference->_type == 'article' => '/journal/' + reference->slug.current,
    reference->_type == 'promo' => '/promo/' + reference->slug.current,
    reference->_type == 'page' => '/' + reference->slug.current,
    href
  ),
  'count': select(
    reference->_type == 'collection' => reference->count,
  ),
  image->,
  // reference->,
  altReference->
`;

const GroqContentLink = /* groq */ `
  markDefs[] {
    ...,
    _type == 'annotationLink' => {
      ${GroqLink}
    }
  }
`;

const GroqLinks = /* groq */ `
  ...,
  link {
    ${GroqLink}
  },

  links[] {
    ${GroqLink},

    links[] {
      ${GroqLink},
    }
  },

  _type == "link" => {
    ${GroqLink}
  }
`;

const GroqMenu = /* groq */ `
  ${GroqLinks}
`;

export const GroqImage = /* groq */ `
  ...,
  asset ->,
  caption[] {
    ...,
    ${GroqContentLink}
  }
`;

export const GroqVideo = /* groq */ `
  ...,
  asset->,
  "aspectRatio": asset->data.aspect_ratio,
  "streamUrl": "https://stream.mux.com/" + asset->playbackId + ".m3u8",
  "mp4LowUrl": "https://stream.mux.com/" + asset->playbackId + "/low.mp4",
  "thumbnailUrl": "https://image.mux.com/" + asset->playbackId + "/thumbnail.jpg"
`;

export const GroqProduct = /* groq */ `
product-> {
  ...,
}
`;

const GroqCaption = /* groq */ `
  ...,
  ${GroqContentLink}
`;

export const GroqWithProduct = /* groq */ `
  ...,
  ${GroqProduct}
`;

const GroqContent = /* groq */ `
  ...,
  ${GroqContentLink},
  
  caption[] { 
    ${GroqCaption} 
  },

  image {
    ${GroqImage},
    caption[] { ${GroqCaption} },
    image {
      ${GroqImage},
    }
  },

  images[] {
    ...,
    caption[] { ${GroqCaption} },
    image {
      ${GroqImage},
      caption[] { ${GroqCaption} }, // Redundant?
    },
  },

  video {
    ${GroqVideo}
  }
`;

export const GroqDefault = /* groq */ `
...,

image { ${GroqImage} },
video { ${GroqVideo} },

${GroqLinks},

// Home
segments[] {
  ...,
  sections[] {
    ${GroqContent},
    link { ${GroqLink} },
    cards[] {
      ...,
      image { ${GroqImage} },
      video { ${GroqVideo} },
      link { ${GroqLink} },
    },
    choices[] {
      ...,
      products[] {
        ${GroqWithProduct},
      }
    },
    productGroups[]-> {
      ...,
      products[]-> {
        ...,
      }
    },
    products[] {
      ${GroqWithProduct},
    },
  }
},

// Products
accordion[] {
  ...,
  ${GroqLinks},
  defined(_ref) => @->,
},

gallery[] {
  ...,
  image { ${GroqImage} },
  video { ${GroqVideo} },
},

// Pages
excerpt[] {
  ${GroqContent},
},


// Standard
content[] {
  ${GroqContent},

  link { ${GroqLink} }, 

  excerpt[] {
    ${GroqContent},
  },

  content[] {
    ${GroqContent},
  },
  
  caption[] {
    ${GroqCaption} 
  },

  cards[] {
    ...,
    
    ${GroqProduct},

    media {
      ...,
      image { ${GroqImage} },
      video { ${GroqVideo} },
    },
    
    caption[] { 
      ...,
      ${GroqContentLink}
    },

  },

  image {
    ${GroqImage}, 
    image { ${GroqImage}, },
    caption[] { ${GroqCaption} },
  },

  image1 {
    ...,
    image { ${GroqImage}, },
    caption[] { ${GroqCaption} },
  },

  image2 {
    ...,
    image { ${GroqImage}, },
    caption[] { ${GroqCaption} },
  },

  collection->,

},

// Mobile
mobileContent[] {
  ${GroqContent},
},

editorialContent[] {
  ${GroqContent},

  link { ${GroqLink} }, 

  content[] { ${GroqCaption} },

  products[] { product-> },

  rows[] {
    ...,
    content[] { ${GroqCaption} },
  },

  image1 {
    ...,
    image { ${GroqImage}, },
    caption[] { ${GroqCaption} },
  },

  image2 {
    ...,
    image { ${GroqImage}, },
    caption[] { ${GroqCaption} },
  },

  images[] {
    ...,
    image {${GroqImage}, },
    caption[] { ${GroqCaption} }
  }
},

landing {
  ...,
  caption[] {
    ${GroqCaption}
  }
},

// Sidebar
sidebar-> {
  ...,
  links {
    ...,
    links[] {
      ...,
      link {
        ${GroqLink}
      }
    }
  },
},

article-> {
  ...,
  link { ${GroqLink} },
  image { ${GroqImage} },
  video { ${GroqVideo} },
},

// Packing section
packing-> {
  ...,
  media {
    ...,
    image { ${GroqImage} },
    video { ${GroqVideo} },
  },
  backgroundDiagram {
    ...,
    asset->,
  },
  items[] {
    ...,
    link {
      ${GroqLink}
    },
    diagram {
      ...,
      asset->,
    },
    thumbnail {
      ...,
      asset->
    }
  },
},

// Regeneration
cards[] {
  ...,
  link {
    ${GroqLink}
  }
},

// Submenus
menu1[] { ${GroqMenu} },
menu2[] { ${GroqMenu} },
menu3[] { ${GroqMenu} },
menu4[] {${GroqMenu},},
menu5[] {${GroqMenu},},
menu6[] {${GroqMenu},},

// Footer
right1[] {${GroqMenu},},
right2[] {${GroqMenu},},

images[] {
  ...,
  asset->
},

// Header
accountMenu[] {
  ${GroqMenu},
},

mainMenu[] {
  ${GroqMenu},
},

// Product Groups
"productGroups": *[_type == "productGroup" && ^._id in products[]._ref] {
  label,
  products[]-> {
    "title": store.title,
    "shopifyId": store.id,
    "handle": store.slug.current,
  },
},

// Related products
related[]->,
products[]->,

// Product Collection
collection->,

// Submenu features
features[] {
  ...,
  link {
    ...,
    ${GroqLink},
  },
  media {
    ...,
    image { ${GroqImage} },
    video { ${GroqVideo} },
  },
},

// Banners
banners[]-> {
  ...,
  content[] {
    ...,
    ${GroqContentLink}
  }
}
`;
